/*=========================================================================================
  File Name: moduleSurgeryCategoryMutations.js
  Description: SurgeryCategory Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.surgeryCategories.unshift(item)
  },
  SET_SurgeryCategory(state, surgeryCategories) {
    state.surgeryCategories = surgeryCategories
  },
  UPDATE_SurgeryCategory(state, SurgeryCategory) {
    const SurgeryCategoryIndex = state.surgeryCategories.findIndex((p) => p.ID == SurgeryCategory.ID)
    Object.assign(state.surgeryCategories[SurgeryCategoryIndex], SurgeryCategory)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.surgeryCategories.findIndex((p) => p.ID == itemId)
    state.surgeryCategories.splice(ItemIndex, 1)
},
}
