<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

    <div class="mt-6 ">

    <vs-card>

  <div class="p-6 vx-row w-full">
        <!-- NAME -->
        <vs-input
          :label="$t('NameEn')"
          v-model="Surgery.NameEN"
          class="mt-5 vx-col lg:w-1/3 w-full"
          name="nameen"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('nameen')">{{
          errors.first("name_en")
        }}</span>

        <vs-input
          :label="$t('NameAr')"
          v-model="Surgery.NameAR"
         class="mt-5 vx-col lg:w-1/3 w-full"
          name="namear"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('namear')">{{
          errors.first("name_ar")
        }}</span>

          <div   class="mt-5 vx-col lg:w-1/3 w-full">
                    <label class="text-sm"
                      > {{ $t("SurgeryCategory") }}
                    </label>
                    <v-select
                      label="Name"
                      class="w-full"
                      v-model="Surgery.SurgeryCategoryID"
                      :options="surgeryCategories"
                      :reduce="ID => ID.ID"
                    />
                  </div>
                
                <div  class="mt-5 vx-col lg:w-1/3 w-full" >
                    <label class="text-sm "
                      > {{ $t("specialities") }}
                    </label>
                    <v-select
                      label="Name"
                      class="w-full"
                      v-model="Surgery.SpecialtyID"
                      :options="specialities"
                      :reduce="ID => ID.ID"
                    />
                  </div>


      
  </div>
<div  class="mt-10  w-full">
        <p>Included Surgery Items </p>
        <div class="vx-row">
            <div
              v-for="item in IncludedItems"
              :key="item.ID"
              class="vx-col lg:w-1/4  mt-5">
              <vs-checkbox  v-model="item.IsChecked">{{
                item.Name
              }}</vs-checkbox>

              <span alt="" :src="item.Name" />
            </div>
        </div>
        
      </div>
    <div class=" items-center p-6">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">{{
        $t("Save")
      }}</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="$router.go(-1)"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
</vs-card>
    </div>

</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleSurgeryItem from "@/store/settings/surgeryItem/moduleSurgeryItem.js";
import moduleSurgeryCategory from "@/store/settings/surgeryCategory/moduleSurgeryCategory.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";

import moduleSurgery from "@/store/settings/surgery/moduleSurgery.js";

export default {


  data() {
    return {
       isMounted: false,
      // Data Sidebar
       SurgeryItems: [],
      Surgery:{},

    };
  },

  computed: {
   surgeryCategories() {
      return this.$store.state.SurgeryCategoryList.surgeryCategories;
    },
        specialities() {
      return this.$store.state.SpecialtyList.specialities;
    },

       IncludedItems() {
      debugger;
      return this.$store.state.SurgeryItemList.surgeryIncludedItems;
    },

    isFormValid() {
      return !this.errors.any() && this.Surgery && this.Surgery.SurgeryCategoryID &&  this.Surgery.SpecialtyID;
    }
  },

  methods: {

    getSurgery() {
      this.$store
        .dispatch("SurgeryList/GetLockupSurgery", this.$route.params.Id)
        .then((res) => {
            debugger;
          this.Surgery = res.data.Data;

          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    submitData() {
      this.$validator.validateAll().then(result => {
      debugger
        if (result) {
       this.Surgery.SurgeryItems =
        this.IncludedItems.filter((obj) => obj.IsChecked).map((b) => {
          //  function(val) {
          debugger;
          var obj = {};
          obj.SurgeryItemLockUpID = b.ID;
          obj.SurgeryID = this.Surgery.ID;
          obj.ID = b.SurgeryItemID==null?0 :  b.SurgeryItemID;
          return obj;
        });
          if (this.Surgery.ID !== null && this.Surgery.ID >= 0) {
   debugger
        this.$vs.loading();
        this.$store
          .dispatch(
            "SurgeryList/UpdateSurgery",
            this.Surgery
          )
          .then((res) => {
            
            this.$vs.loading.close();
            this.$router.go(-1);
            window.showSuccess(res.data.message);

          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showSuccess(res.data.message);
          });


          } else {
            delete this.Surgery.ID;

            this.$store
              .dispatch("SurgeryList/AddSurgery", this.Surgery)
              .then((res) => {
            
            this.$vs.loading.close();
            this.$router.go(-1);
            window.showSuccess(res.data.message);

          })
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          }



          //this.$store.dispatch("foodTypeList/fetchfoodTypeListItems");
        }
      });
    }
  },
created(){

    if (!moduleSurgeryCategory.isRegistered) {
      this.$store.registerModule("SurgeryCategoryList", moduleSurgeryCategory);
      moduleSurgeryCategory.isRegistered = true;
    }
  if (!moduleSurgery.isRegistered) {
      this.$store.registerModule("SurgeryList", moduleSurgery);
      moduleSurgery.isRegistered = true;
    }


    this.$store.dispatch("SurgeryCategoryList/GetAllSurgeryCategories");

     if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }

    this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    this.getSurgery();

      debugger;
    if (!moduleSurgeryItem.isRegistered) {
      this.$store.registerModule("SurgeryItemList", moduleSurgeryItem);
      moduleSurgeryItem.isRegistered = true;
    }
  if (
      this.Surgery.SurgeryItems == null ||
      this.IncludedItems == null ||
      this.Surgery.SurgeryItems.length == 0 ||
      this.IncludedItems.length == 0
    ) {
      this.$store
        .dispatch(
          "SurgeryItemList/GetSurgeryIncludedItems",
          this.$route.params.Id
        )
        .then((res) => {
        });
    }
    this.IncludedItems;
},
  components: {
    VuePerfectScrollbar
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
