/*=========================================================================================
  File Name: moduleSurgeryItemMutations.js
  Description: SurgeryItem Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.surgeryItems.unshift(item)
  },
  SET_surgeryIncludedItem(state, surgeryIncludedItems) {
    debugger
    state.surgeryIncludedItems = surgeryIncludedItems
  },
  SET_SurgeryItem(state, surgeryItems) {
    debugger
    state.surgeryItems = surgeryItems
  },
  SET_surgeryExcludedItem(state, surgeryExcludedItems) {
    debugger
    state.surgeryExcludedItems = surgeryExcludedItems
  },
  SET_surgeryDoctorItem(state, surgeryDoctorItems) {
    debugger
    state.surgeryDoctorItems = surgeryDoctorItems
  },
  SET_surgeryDocItem(state, surgeryDocItems) {
    debugger
    state.surgeryDocItems = surgeryDocItems
  },
  UPDATE_SurgeryItem(state, SurgeryItem) {
    const SurgeryItemIndex = state.surgeryItems.findIndex((p) => p.ID == SurgeryItem.ID)
    Object.assign(state.surgeryItems[SurgeryItemIndex], SurgeryItem)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.surgeryItems.findIndex((p) => p.ID == itemId)
    state.surgeryItems.splice(ItemIndex, 1)
  },
}
