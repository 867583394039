/*=========================================================================================
  File Name: moduleSurgeryItemActions.js
  Description: SurgeryItem Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
    AddSurgeryItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/SurgeryItemLockUp/AddSurgeryItemLockUp", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetAllSurgeryItems({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("api/SurgeryItemLockUp/GetAllSurgeryItemLockUps")
                .then((response) => {
                    commit('SET_SurgeryItem', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetHospitalSurgeryIncludedItems({ commit }, itemid) {
        if (itemid == undefined)
            itemid = 0;
        return new Promise((resolve, reject) => {
            axios.get("api/HospitalSurgery/GetHospitalSurgeryIncludedItems?ID=" + itemid)
                .then((response) => {
                    commit('SET_surgeryIncludedItem', response.data.Data)
                    commit('SET_SurgeryItem', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetNewHospitalSurgeryIncludedItems({ commit }, item) {
        debugger
        if (item == undefined)
            item = null;

        if (item.ID == undefined) {
            item.ID = null;
        }
        debugger
        return new Promise((resolve, reject) => {
            axios.post("api/HospitalSurgery/GetNewHospitalSurgeryIncludedItems",
                item)
                .then((response) => {
                    debugger
                    commit('SET_surgeryIncludedItem', response.data.Data)
                    commit('SET_SurgeryItem', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetSurgeryIncludedItems({ commit }, itemid) {
        if (itemid == undefined)
            itemid = 0;
        return new Promise((resolve, reject) => {
            axios.get("api/Surgery/GetSurgeryIncludedItems?ID=" + itemid)
                .then((response) => {
                    commit('SET_surgeryIncludedItem', response.data.Data)
                    commit('SET_SurgeryItem', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetHospitalSurgeryExcludedItems({ commit }, itemid) {
        if (itemid == undefined)
            itemid = 0;
        return new Promise((resolve, reject) => {

            axios.get("api/HospitalSurgery/GetHospitalSurgeryExcludedItems?ID=" + itemid)
                .then((response) => {
                    debugger
                    commit('SET_surgeryExcludedItem', response.data.Data)
                    commit('SET_SurgeryItem', response.data.Data)

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetNewHospitalSurgeryExcludedItems({ commit }, item) {
        if (item == undefined)
            item = 0;
        return new Promise((resolve, reject) => {

            axios.post("api/HospitalSurgery/GetHospitalSurgeryExcludedItems", item)
                .then((response) => {
                    commit('SET_surgeryExcludedItem', response.data.Data)
                    commit('SET_SurgeryItem', response.data.Data)

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetHospitalDoctorItems({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/HospitalSpecialtyDoctor/SearchHospitalSpecialtyDoctors",
                    item
                )
                .then(response => {
                    debugger
                    commit("SET_surgeryDoctorItem", response.data.Data);
                    resolve(response);
                    debugger
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdateSurgeryItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/SurgeryItemLockUp/UpdateSurgeryItemLockUp", item)
                .then((response) => {
                    commit('UPDATE_SurgeryItem', item)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetSurgeryItem({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios.post("api/SurgeryItemLockUp/GetSurgeryItemLockUp", itemid)
                .then((response) => {
                    commit('UPDATE_SurgeryItem', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteSurgeryItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/SurgeryItemLockUp/DeleteSurgeryItemLockUp?ID=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
}